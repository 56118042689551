.ant-notification-notice {
  border-radius: 12px !important;
  min-width: 470px !important;
}

.ant-notification-notice-message {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.ant-notification-notice-icon-success {
  color: #00BD52 !important;
}

.ant-notification-notice-icon-error {
  color: #D92D20 !important;
}

.ant-notification-notice-success {
  border: 1px solid #72FFB2;
  background: #F7FFFA;
}

.ant-notification-notice-error {
  border: 1px solid #F04438;
  background: #F7FFFA;
}