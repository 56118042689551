.ant-radio-button-wrapper-checked {
    background-color: #00BD52 !important;
}

.ant-radio-button-wrapper-checked::before {
    background-color: unset !important;
}

.ant-radio-button-wrapper {
    border-color: transparent !important;
    border: unset !important;
}

.ant-radio-button-wrapper:hover {
    color: #00BD52;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
    color: black;
}