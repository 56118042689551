.ant-collapse {
  border: none;
  border-radius: 0px !important;
  background: #FFFF;
}

.ant-collapse-item{
  background: #FFFF;
  border-radius: 0px !important;
  border: none !important;
}

.ant-collapse-header {
  background: #EEFFF4;
  padding: 15px 15px !important;
  display: flex !important;
  align-items: center !important;
}

.ant-collapse-content {
  border: none !important;
  /* background-color: #F9FAFB !important; */
}

.ant-collapse-header-text {
  color: #01A24A;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  padding-top: 2px;
}

.ant-collapse-expand-icon {
  padding-inline-start: 30px !important;
  margin-right: 10px;
  color: #01A24A;
}

.ant-collapse-content-box {
  background: #F9FAFB !important;
}

/* .ant-collapse .ant-collapse-item .ant-collapse-item-active .ant-collapse-expand-icon {
  transform: rotate(180deg) !important;
  padding-right: 30px !important;
  padding-bottom: 10px !important;
}

.ant-collapse .ant-collapse-item .ant-collapse-header .ant-collapse-expand-icon {
  transform: rotate(90deg) !important;
  padding-right: 30px !important;
  padding-bottom: 10px !important;
} */