.show-submenu {
    max-height: 500px;
    opacity: 1;
    transition: max-height 0.8s ease, opacity 0.8s ease;
}

.hide-submenu {
    max-height: 0;
    opacity: 0;
    transition: max-height 0.8s ease, opacity 0.8s ease;
}