/* @import '~antd/dist/antd.css'; */
@import url("./components/styles/collapse.css");
@import "./components/styles/button.css";
@import url("./components/styles/menu.css");
@import "./components/styles/notification.css";

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #888;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #666;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-form-item-explain-error {
  color: red;
}

.field-required::after {
  content: "*";
  color: #ff5151;
  margin-left: 3px;
}

.form-input {
  height: 2.3rem;
  border: 1px solid #EAECF0;
  border-radius: 6px;
  padding: 3px 10px;
}

.form-input:hover {
  border-color: #00BD52;
}

.table-list {
  background: #FFFF;
}

.list {
  background: #FFFF;
}

.ant-select-selector {
  height: 48px !important;
  display: flex !important;
  align-items: center !important;
}

.ant-select-selector:hover {
  border: 1px solid #00BD52 !important;
}

.ant-select-selector:active {
  border: 1px solid #00BD52 !important;
}

.ant-select-selection {
  background-color: green;
}

.ant-input-group-wrapper {
  border: none;
  border-radius: 0;
  padding: 0;
}

#form-create-donor_submissionFunding_funding_total {
  height: 47px !important;
}

#form-create-donor_submissionFunding_funding_total:hover {
  border: 1px solid #00BD52 !important;
}

#form-create-donor_submissionFunding_funding_total:active {
  border: 1px solid #00BD52 !important;
}

#text-sub-panel {
  color: #1D2939 !important;
}

.ant-input-group-addon {
  background: #FFFF !important;
}

.alert-success-color {
  color: #72CE99 !important;
}

.alert-error-color {
  color: #E9585B !important;
}

.alert-warn-color {
  color: #F1C857 !important;
}

.alert-info-color {
  color: #3583EA !important;
}

.css-13cymwt-control {
  height: 48px !important;
}

.css-1p3m7a8-multiValue {
  background-color: #01A24A !important;
  color: white;
  border-radius: 6px !important;
}

.css-wsp0cs-MultiValueGeneric {
  color: white !important;
}

.ant-btn-primary {
  background-color: #00BD52 !important;
  color: white !important;
}

.ant-modal-confirm-btns {
	display: flex;
	justify-content: center !important;
}

.ant-modal-confirm-btns button {
  font-weight: 500;
  width: 170px !important;
  height: 44px !important;
}

.ant-table-thead .ant-table-cell {
  background-color: #077439 !important;
  color: white !important;
  border-radius: 0% !important;
}

.right-cursor {
  color: #D0D5DD !important;
}

.house-icon {
  color: #667085 !important;
}

.text-heading {
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  line-height: 28px;
  color: #1D2939;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.text-sub-heading {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #1D2939;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.text-body {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 35px;
  color: #1D2939;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.text-sub-body {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 35px;
  color: #1D2939;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.ant-spin-dot-item {
  background: #00BD52 !important;
}

.ant-spin-dot {
  color: #01A24A !important;
}

.ant-spin-text {
  color: #01A24A;
}

.ant-select-arrow {
  color: #344054 !important;
}

.ant-pagination {
  border: 1px solid #D0D5DD;
  filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05));
  border-radius: 8px !important;
  padding: 0 15px 0 15px;
  background: white;
  border-color: #D0D5DD !important;
  border: 1px solid #D0D5DD !important;
}

.ant-pagination-item {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.ant-pagination-item-active {
  border-color: #D0D5DD !important;
  border-radius: 0 !important;
  border-top: none !important;
  border-bottom: none !important;
  background: #F9FAFB !important;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
}

.ant-pagination-item-active a {
  color: #1D2939 !important;
  font-weight: 600;
  font-size: 14px;
}

.ant-pagination-prev {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #344054 !important;
}

.ant-pagination-next {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}
/* 
.ant-tag {
  font-weight: 500 !important;
  border-radius: 10px !important;
  height: 25px !important;
  color: #344054;
} */

.placeholder-text {
  padding-left: 10px;
  font-weight: 600;
  color: #667085 !important;
  padding-top: -10px;
}

.ant-switch {
  background: grey !important;
}

.ant-switch-checked {
  background: #00BD52 !important;
}

.ant-steps-item-icon {
  display: none;
}

.ant-steps-item-title {
  display: inline-block;
  padding-inline-end: 0px !important;
  font-size: 16px;
  line-height: 32px;
}

.ant-steps-item-icon {
  display: none !important;
}

.ant-steps-item {
  padding-inline-start: 0px !important;
}

.ant-upload-drag {
  border: 1px solid #EAECF0 !important;
  background: #FFFF !important;
  border-radius: 12px !important;
  /* height: 150px !important; */
}

.break-line {
  width: 98%;
  border: 1px solid #BDBDBD;
}

.quill {
  height: 200px;
}

.ql-picker.ql-size .ql-picker-label::before,
.ql-picker.ql-size .ql-picker-item::before {
	content: attr(data-value) !important;
}

.btn-quill {
	border: solid 1px #000;
	padding: 5px;
}

.btn-quill:hover {
  background: #BDBDBD;
}

.ql-toolbar {
  background: #EAECF0;
}

.ql-container {
  height: 78% !important;
  border-radius: 8px;
}

.warning-box {
  border: 1px solid #F5AE70;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  background: #FFFCF5;
  border-radius: 10px;
  margin-bottom: 20px;
  font-weight: 700;
  color: #8F480A;
}

.button-style {
  display: flex;
  justify-content: center;
  align-items: center;
}

.column-head {
  width: 99%;
  height: 70px;
  background: #EEFFF4;
  color: #01A24A;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-transform: capitalize;
  border-radius: 8px;
  display: flex;
  align-items: center;
}

.ant-radio-inner {
  width: 20px !important;
  height: 20px !important;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #00BD52 !important;
  background: #EEFFF4 !important;
  width: 20px !important;
  height: 20px !important;
}

.ant-radio-checked .ant-radio-inner::after {
  background: #00BD52 !important;
  width: 120% !important;
  height: 120% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  left: 6.2px !important;
  top: 6.2px !important;
}

.ant-radio-inner:focus {
  border-color: #00BD52 !important;
}