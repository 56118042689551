// .ant-steps-item-wait {
//   .ant-steps-item-title::after {
//     background-color: #BDBDBD !important;
//     margin-top: -3px !important;
//   }
// }

// .ant-steps-item-finish {
//   .ant-steps-item-title::after{
//     background-color: #00BD52 !important;
//     margin-top: -3px !important;
//   }
// }
.ant-tabs-tab.ant-tabs-tab-active {
  color: #00BD52 !important;

  .ant-tabs-tab-btn {
    color: #00BD52 !important;
    // background: #00BD52 !important;
  }

  // .ant-tabs-tab-btn::hover {
  //   color: #00BD52 !important;
  //   // background: #00BD52 !important;
  // }
}

.ant-tabs-tab:hover{
  color: #00BD52 !important;

}

.step-2 {
  .ant-steps-item-active {
    .ant-steps-item-title::after {
      background-color: #EAECF0 !important;
      margin-top: -3px !important;
    }
  }

  .ant-steps-item-wait {
    .ant-steps-item-title::after {
      background-color: #EAECF0 !important;
      margin-top: -3px !important;
    }
  }

  .ant-steps-item-finish {
    .ant-steps-item-title::after {
      background-color: #00BD52 !important;
      margin-top: -3px !important;
    }
  }
}

.ant-collapse {
  background: #FFFF;
}

.ant-collapse-item{
  background: unset;
  border: 1px solid #f0f0f0;
}

.ant-collapse-header {
  background: #00BD52 ;
  padding: 10px 0px !important;
  display: flex !important;
  align-items: center !important;
  border: 1px solid #e0e0e0 !important;
}

.ant-collapse-content {
  border: 1px solid #f0f0f0;
  /* background-color: #F9FAFB !important; */
}

.ant-collapse-header-text {
  color: #fff;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 27px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  padding-top: 2px;
  padding-left: 10px;
}

.ant-collapse-expand-icon {
  padding-inline-start: 15px !important;
  margin-right: 10px;
  color: #fff;
}

.ant-collapse-content-box {
  background: #F9FAFB !important;
  border: 1px solid #f0f0f0;
}