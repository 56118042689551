.status {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text-sub-panel {
  color: #1D2939;
}

.ant-collapse-content-box {
  background: #FFF !important;
}

.custom-panel {
  .ant-collapse-content-box {
    background: #FFF !important;
    padding: 0 !important;
  }
}

.custom-panel-style {
  background: "#FFFF" !important;
  padding: 0 !important;
}

.ant-input-number-input {
  text-align: right !important;
}

.ant-input-disabled, .ant-input-number-disabled {
  color: #000 !important;
}

.ant-select-disabled:where(.css-14wwjjs).ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-select-disabled:where(.css-14wwjjs).ant-select-multiple .ant-select-selection-item {
  color: #000 !important;
}

.ant-picker .ant-picker-input >input[disabled] {
  color: #000 !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
  content: '14';
  font-size: 14px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
  content: '16';
  font-size: 16px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  content: '18';
  font-size: 18px !important;
}